import * as React from 'react';
import { OptimizedImage } from '../optimized-image';
import { Link } from '../../../i18n';
import { Button, Container, Box } from '@material-ui/core';
import { WithTranslation } from 'next-i18next';
import Icon from '../Icon';
import { announceButtonHref } from '@app/lib/constants/announce-url';

export const Footer: React.FunctionComponent<WithTranslation> = ({ t }) => {
  return (
    <footer>
      <Container>
        <div>
          <Link href="/index" as="/">
            <a>
              <OptimizedImage
                src={require('@app/public/images/logo-full.svg')}
                alt="Logo da CliqueiMudei"
                ext="svg"
              />
            </a>
          </Link>
        </div>
        <div>
          <nav className="footer-nav">
            <Link href={t`common:menu.about-us.url`} as={t`common:menu.about-us.as`} passHref>
              <a>{t`common:menu.about-us.label`}</a>
            </Link>
            <Link href={t`common:menu.signin.url`} as={t`common:menu.signin.as`} passHref>
              <a>{t`common:menu.signin.label`}</a>
            </Link>
            <a
              href={t`common:menu.contact.url`}
              rel="nofollow"
              target="_blank"
            >{t`common:menu.contact.label`}</a>
          </nav>
          <Box
            textAlign="center"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="#777"
            my={2}
          >
            <Box mx={2}>
              <a href="https://facebook.com/cliqueimudei" target="_blank" rel="nofollow">
                <Icon icon="custom-facebook" width="24px" height="24px" />
              </a>
            </Box>
            <Box mx={2}>
              <a href="https://instagram.com/cliqueimudei" target="_blank" rel="nofollow">
                <Icon icon="custom-instagram" width="24px" height="24px" />
              </a>
            </Box>
            <Box mx={2}>
              <a
                href="https://www.linkedin.com/company/cliqueimudei"
                target="_blank"
                rel="nofollow"
              >
                <Icon icon="custom-linkedin" width="24px" height="24px" />
              </a>
            </Box>
            <Box mx={2}>
              <a href="https://twitter.com/cliqueimudei" target="_blank" rel="nofollow">
                <Icon icon="custom-twitter" width="24px" height="24px" />
              </a>
            </Box>
          </Box>
          <Box className="copyright" textAlign="center">
            <p>{t`common:copyright`}</p>
            <Link href={t`common:menu.privacy.url`} as={t`common:menu.privacy.as`} passHref>
              <a>{t`common:menu.privacy.label`}</a>
            </Link>{' '}
            |{' '}
            <Link href={t`common:menu.cookies.url`} as={t`common:menu.cookies.as`} passHref>
              <a>{t`common:menu.cookies.label`}</a>
            </Link>
          </Box>
        </div>
        <div>
          <a href={announceButtonHref}>
            <Button variant="contained" color="primary" size="large">
              {t`common:menu.advertise.label`}
            </Button>
          </a>
        </div>
        {/* <div>
          <a href="https://api.whatsapp.com/send?phone=5516997711498" target="_blank">
            <Button variant="contained" color="primary" size="large">
              Suporte
            </Button>
          </a>
        </div> */}
      </Container>
    </footer>
  );
};
