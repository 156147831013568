import * as React from 'react';
import { OptimizedImage } from '../optimized-image';
import { Link } from '../../../i18n';
import {
  Container,
  Button,
  Drawer,
  List,
  ListItem,
  Box,
  Divider,
  IconButton,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { WithTranslation } from 'next-i18next';
import { UndecoratedLink } from '@app/components/UndecoratedLink';
import styled from 'styled-components';

interface MenuItem {
  label: string;
  href: string;
  newTab: boolean;
}

const StyledIcon = styled(IconButton)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const Header: React.FunctionComponent<WithTranslation> = ({ t }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const menuItems = [] as MenuItem[];
  return (
    <header>
      <Container fixed maxWidth="xl">
        <div>
          <Link href="/index" as="/">
            <a>
              <OptimizedImage
                src={require('@app/public/images/logo_bk.svg')}
                ext="svg"
                alt="CliqueiMudei logo"
                height="34px"
              />
            </a>
          </Link>
        </div>
        <nav>
          <StyledIcon
            onClick={() => {
              setIsOpen(true);
            }}
            children={<MenuIcon />}
          />
          <Drawer
            anchor="right"
            open={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
          >
            <List>
              <ListItem button>
                <UndecoratedLink href={t`common:menu.signin.as`} newTab>
                  <Button color="primary">{t`common:menu.signin.label`}</Button>
                </UndecoratedLink>
              </ListItem>
              <Divider />
              <Box color="primary" mt={1} ml={1}>
                {menuItems.map((it, i) => {
                  return (
                    <ListItem button key={i}>
                      <UndecoratedLink
                        key={i}
                        href={it.href}
                        variant="subtitle1"
                        newTab={it.newTab}
                      >
                        {it.label}
                      </UndecoratedLink>
                    </ListItem>
                  );
                })}
              </Box>
            </List>
          </Drawer>
          <Box display={{ xs: 'none', md: 'flex' }} alignItems="center">
            {menuItems.map((it, i) => {
              return (
                <UndecoratedLink key={i} variant="subtitle1" href={it.href} newTab={it.newTab}>
                  {it.label}
                </UndecoratedLink>
              );
            })}
            <UndecoratedLink href={t`common:menu.signin.as`} newTab>
              <Button color="primary">{t`common:menu.signin.label`}</Button>
            </UndecoratedLink>
          </Box>
        </nav>
      </Container>
    </header>
  );
};
