import * as React from 'react';
import { Header } from './header';
import { Footer } from './footer';

import '@app/styles/layout.scss';
import { WithTranslation } from 'next-i18next';
import { SEO } from '../seo/seo';

interface OwnProps {
  namespace?: string;
  isHome?: boolean;
  seoContext?: { [key: string]: any };
  hideHeader?: boolean;
}

type Props = OwnProps & WithTranslation;

export const Layout: React.FunctionComponent<Props> = ({
  children,
  isHome,
  namespace,
  seoContext,
  hideHeader = false,
  ...props
}) => {
  return (
    <>
      <SEO {...props} namespace={namespace || 'common'} seoContext={seoContext} />
      {!hideHeader && <Header {...props} />}
      <main className="main-section">{children}</main>
      <Footer {...props} />
    </>
  );
};
